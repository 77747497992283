.hero-component{
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 0;
}


.body-container{
  position: absolute;
  bottom: 48px;
  left: 48px;
  font-size: 64px;
  z-index: 3;
}

@media only screen and (max-width: 770px) {
  .body-container{
    bottom: 16%;
    left: 3.5%;
  }
}

.body-container h1{

margin-block-end: 0;
margin-block-start: 0;
}

.body-container h3{
  /* font-family: Arial, Helvetica, sans-serif; */
  margin-block-end: 0;
margin-block-start: 0;
}