.footer-component{
    height: 600px;
    width: 100%;
    background-color: rgba(223, 220, 215, 0.80);
    position: relative;
    overflow: hidden;
    
}

.body-container{
    z-index: 5;
}
.footer-links-container,
.footer-right-container{
    z-index: 5;
    position: absolute;
}

.footer-right-container{
    width: 30%;
    bottom: 60px;
    right: 48px;
    text-align: right;
}

.footer-right-container h5{
 text-align: right;
 font-size: 16px;
}
.footer-links-container{
    width: 30%;
    bottom: 60px;
    left: 24%;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .footer-links-container{
        top: 48px;
        left: 3.5%;
        width: 93%;
    }

    .footer-right-container{
        bottom: 112px;
    }

    .footer-right-container{
        right: 3.5%;
    } 

    .footer-component{
        margin-bottom: 64px;
    }
}