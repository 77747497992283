.fuzz-layer{
  width: 100%;
  z-index: 99999999999;
  opacity: 0.4;
  background-image: url('../Media/noise.webp');
  background-repeat: repeat;
  position:fixed;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
}

.background-component{
  height: 100%;
  width: 100vw;
  max-width: 1920px;
  position: absolute;
  top: 0;
  left: 0;
}

.background-component-noise-img{
  height: 100%;
  width: 100%;
}

.background-blob-storage-hero{
  height: 100vh;
  width: 100%;
}

.background-blob-vectori,
.background-blob-vectorii,
.background-blob-ellipse,
.background-blob-ellipsei
{
  width: 100%;
  height: 100%;
  position: absolute;
}

.background-blob-vectori-img{
  height: 100%;
  width: 100%;
}

.background-blob-vectorii-img{
  height: 100%;
  width: 100%;
}

.background-blob-ellipse-img{
  height: 60%;
  width: 60%;
  rotate: 20deg;
}

.background-blob-ellipsei-img{
  height: 30%;
  width: 30%;
  rotate: 20deg;
}

.blob-img{
  position: relative;
  filter: blur(100px);
}

@media only screen and (max-width: 767px) {
  .blob-img{
    position: relative;
    /* filter: blur(40px); */
  }
}

.blob-i,
.blob-ii,
.blob-iii{
  opacity: 1;
}

/* @media only screen and (min-width: 767px) { */

  .blob-color-1 .background-blob-ellipse,
  .blob-color-1 .background-blob-vectorii,
  .blob-color-1 .background-blob-vectori,
  .blob-color-2 .background-blob-vectori,
  .blob-color-2 .background-blob-vectorii,
  .blob-color-2 .background-blob-ellipse,
  .blob-color-2 .background-blob-ellipsei,
  .blob-color-3 .background-blob-vectori,
  .blob-color-3 .background-blob-vectorii,
  .blob-color-3 .background-blob-ellipse,
  .blob-color-3 .background-blob-ellipsei,
  .blob-color-4 .background-blob-vectori,
  .blob-color-4 .background-blob-vectorii,
  .blob-color-4 .background-blob-ellipse,
  .blob-color-4 .background-blob-ellipsei
  {
    opacity: 1
  }

  .blob-i,
  .blob-ii,
  .blob-iii{
    opacity: 0;
  }

  .blob-i{
    transition: opacity 1.6s ease-in-out;
    z-index: 0
  }
  .blob-ii{
    transition: opacity 0.9s ease-in-out;
    z-index: 1;
  }
  .blob-iii{
    transition: opacity 3s ease-in-out;
    z-index: -1;
  }
/* } */

@media only screen and (max-width: 767px) {
  .blob-img{
    filter: none;
  }
  .background-blob-container{
    margin-bottom: -80px;
  }
  
  /* .blob-color-1 .blob-i{
    margin-left: -48px;
  }
  .blob-color-1 .blob-ii{
    margin-left: 48px;
  } */
  .blob-color-1 .blob-iii{
    margin-top: -48px;
  }

  .blob-color-2 .background-blob-vectori-img{
    rotate: 180deg !important;
    left: -60% !important;
  }

  .blob-color-2 .background-blob-vectorii-img{
    left: 30% !important;
    bottom: -70% !important;
  }
  .blob-color-2 .background-blob-ellipse-img{
    left: 45% !important;
    bottom: -30% !important;
  }

  .contact-page .background-component{
    margin-top: 120px;
  }

  .contact-page  .blob-color-1 .blob-i{
    margin-top: 138px;
  }
  .contact-page  .blob-color-1 .blob-ii{
    margin-top: 288px;
  }
  .contact-page  .blob-color-1 .blob-iii{
    margin-top: -538px;
  }

}