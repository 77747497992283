.header-component{
  position: fixed;
  left: 0;
  width: 100%;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-evenly;
  z-index: 999;
  top: 0;
}
.header-wrapper{
  width: 100%;
  transition: top 0.3s ease-in-out;
  position: relative;
  background: linear-gradient(0deg, 
  rgba(217,217,217,0.01) 0%, 
  rgba(217,217,217,0.25) 25%, 
  rgba(217,217,217,0.65) 50%);
  transition: all 0.6s ease-in-out;
  top: 0;

}
.header-container{
  max-width: 1920px;
  margin: auto;
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.header-inner{
  /* display: flex;
  justify-content: space-evenly;
  min-width: 700px; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1280px;
  margin: auto;
}


@media only screen and (min-width: 767px) {
  .header-inner .header-section:first-of-type{
    margin-right: 120px; 
  }
}


.header-link{
  scroll-behavior: smooth !important;
}

.header-m-switch{
  display: none;
}

.header-section{
  padding-right: 3.5%;
}

@media only screen and (max-width: 767px) {

  .header-component{
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    justify-content: flex-end;
    width: 200px;
    height: 100%;
  }

  .header-inner{
    flex-direction: column;
    min-width: unset;
    width: 100%;
  }

  .header-section{
    text-align: right;
    /* margin-right: 12.5%; */
    margin: 24px 12.5% 24px auto;
    padding-right: 3.5%;
  }

  .header-m-switch{
    display:block;
    z-index: 12;
    pointer-events: all !important;
  }

  .header-m-switch-menu-svg,
  .header-m-switch-close-svg{
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: block;
    position: absolute;
    right: 12.5%;
    top: 3.5%;
  }

  .header-m-switch-close-svg{
    background-color: #9BA873;
  }
  .header-m-switch-menu-svg{
    background-color: #384A4E ;
  }

  .header-m-switch-close,
  .header-m-switch-on .header-m-switch-menu
  {
    /* opacity: 0;
    pointer-events: none; */
    display: none;
  }

  .header-m-switch-menu,
  .header-m-switch-on .header-m-switch-close{
    /* opacity: 1;
    pointer-events: all; */
    display: block;
  }

  /* .header-m-switch-menu,
  .header-m-switch-close{
    transition: all 0.6s ease-in-out;
  } */

  .header-wrapper{
    background: none;
  }

  .header-nav-on .header-wrapper {
    background: linear-gradient(90deg, 
    rgba(217,217,217,0) 0%, 
    rgba(217,217,217,0.45) 20%, 
    rgba(217,217,217,0.7) 30%,
    rgba(217,217,217,1) 70%);

  }

  .header-nav-on .header-m-switch-menu-svg{
    pointer-events: all;
  }

  .header-container{
    display: none;
    padding-top: 96px;
    
  }
  .header-component,
  .header-component .header-wrapper{
    pointer-events: none;
  }
  .header-nav-on,
  .header-nav-on .header-wrapper{
    pointer-events: all;
  }

  .header-nav-on .header-container{
    display: block;
  }





}