.split-component-container{
  position: relative;
}

.split-component{
  margin: 120px auto;
  z-index: 5;
  position: relative;
  display: inline;
  background-color: rgba(223, 220, 215, 0.40);
  border-radius: 20px;
  max-width: 1280px;
  height: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
.split-container{
  position:relative
}

.split-container{
  padding: 3.5%;
}

.split-h1 {
  margin-bottom: 42px;
  width: 200%;
}

.split-a {
  font-family: inter-extra-bold;
  color: #231F20;
  font-size: 48px;
  font-weight: 1000;
  line-height: 64px;
  letter-spacing: -3px;
  cursor: default;
  position: relative;
  transition: color .7s ease-in-out;
  transition-delay: .3s;
  z-index: 4;
  text-decoration: none;
  /* margin-right: 12px; */
}

.split-a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;

  opacity: 0;
  background-color: #9BA873;
  filter: blur(24px);
  transition: opacity .7s ease-in-out;
  transition-delay: .3s;
  z-index: -1;
  left: -12px;
  top: -6px;
  border-radius: 35%;
}

.split-a-fx{
  color: rgba(255, 255, 255, 0.95);
}

.split-a-fx:after{
  opacity: 1;
}

#split-two .split-h1{
  /* padding: 0 8%;
  margin: 200px 3.5%; */
}

.split-component{
  display: flex;
}

.split-container{
  width: 48%;
  margin: 0 0.5%
}

.split-accent{
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .split-component{
    flex-direction: column;
    margin: 48px 0 !important;
  }
  .split-container{
    width: auto;
  }

  .split-a::after {
    left: -1px;
    top: 2px;
    filter: none;
    opacity: 1;
    padding: 0;
  }
  .split-h1 {
    width: 100%;
  }
}
