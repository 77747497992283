.contact-component{
    z-index: 5;
    position: relative;
    display: inline;
    background-color: rgba(223, 220, 215, 0.20);
    border-radius: 20px;
    max-width: 1280px;
    height: 100%;
    display: block;
    /* padding: 120px 3.5%; */
    min-height: 100vh;
    margin: auto;
    margin-bottom: 120px;
}

.contact-body{
    display: flex;
}

@media only screen and (max-width: 767px) {
    .contact-body{
        flex-direction: column;
    }

    .contact-left-container,
    .contact-right-container{
        width: 100% !important;
    }

    .contact-words{
        bottom: 16% !important;
        left: 3.5%;
    }
}

.contact-left-container,
.contact-right-container{
    width: 46.5%;
    padding: 3.5% 0;
}

.contact-page .background-component {
    height: auto !important;
}

.contact-words{
    position: absolute;
    bottom: 64px;
    left: 48px;
}