.cards-component{
  width: auto;
  /* padding: 0 8%;
  margin: 0 3.5%; */
  margin: 120px auto; 
  max-width: 1280px;
  position: relative;
}

.cards-component-inner{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
    z-index: 3;
}

.cards-component-title{
  margin: 64px 2%;
  position: relative;
  transition: color .7s ease-in-out;
  transition-delay: .3s;
  z-index: 4;
  text-decoration: none;
  width: fit-content
}

.cards-component-title::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background-color: rgba(223, 220, 215, 0.80);
  filter: blur(24px);
  transition: opacity .7s ease-in-out;
  transition-delay: .3s;
  z-index: -1;
  padding: 48px;
  left: -48px;
  top: -48px;
}


.cards-component-title-fx h2{
  /* color: rgba(255, 255, 255, 0.95); */
}

.cards-component-title-fx:after{
  opacity: 1;
}

.card-wrapper{
  width: 29.333%;
  position: relative;
  height: 500px;
  border-radius: 20px;
  background: rgba(223, 220, 215, 0.80);
  margin: 24px 0;
  pointer-events: none;
}

.card-image{
  position: absolute;
  bottom: 5%;
  left: 10%;
  text-align: left;
  width: 80%;
}

.card-image img{
width: 60%;
}

.card-text{
  position: absolute;
  top: 3.5%;
  left: 10%;
  width: 80%;
}

.card-text h2{
  margin-top: 12px;
}

.body-container h1{
  line-height: 102px;
  font-size: 112px;
}

.card-front{
  position: absolute;
    top: 3.5%;
    left: 10%;
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media only screen and (max-width: 1280px) and (min-width: 1000px) {
  .card-text h3{
    font-size: 36px;
    line-height: 42px;
  }
  .card-wrapper{
    height: 360px;
    
  }
}
@media only screen and (max-width: 1000px) and (min-width: 767px) {
  .card-text h3{
    font-size: 30px;
    line-height: 36px;
  }
  .card-wrapper{
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .cards-component{
    margin: 48px 3.5% !important;
  }

  .cards-component-inner{
    flex-direction: column;
    
  }

  .card-wrapper{
    width: 100%;
    height: 340px;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }

  .cards-component-title{
    margin: 0 12px;
  }

  .card-text{
    left: 3.5% !important;
    width: 93% !important;
  }

  .card-image{
    left: 3.5% !important;
    max-width: 200px;
  }
}
@media only screen and (max-width: 360px) {
  .card-text h3{
    font-size: 36px;
    line-height: 42px;
  }
}

.card-wrapper {
  /* position: relative; */
  /* cursor: pointer; */
  /* overflow: hidden; */
  /* transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s; */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.card-wrapper:hover {
  /* transform: scale(1.05); */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  /* background-color: #f8f8f8; Change to your desired hover background color */
}
