.carousel-component{
    margin: 120px auto;
    width: 1280px;
    position: relative;
}

@media only screen and (max-width: 1280px) {
    .carousel-component{
        margin-top: 72px ;
        margin-bottom: 72px ;
        width: 100%;
        position: relative;
        max-width: 93%;
    }
}

.swiper{
    border-radius: 20px;
}

.swiper-wrapper{

    height: 100%;
    overflow: visible !important;
}

.swiper-slide{
    width: 100%;
}

.carousel-component .slide-container{
    width: 93%;
    max-width: 1280px;
    height: 600px;
    display: flex;
    margin: auto;
    background-color: rgba(217, 217, 217, 0.65);
    border-radius: 20px;
    padding: 56px;
}

.slide-left,
.slide-right{
    display: flex;
    flex-direction: column;
    width: 50%;
}


.slide-title{
    margin-right: 0;
}

.slide-body{
    margin-top: 24px;
    margin-right: 42px;
}

.slide-name{
    
}

.slide-img{
margin: 32px 0;
}

.slide-img img{
    height: 300px;
    width: 300px;
    border-radius: 50%;
}
.slide-right h4, 
.slide-right p{
    text-align: center;
}

.slide-subname{
    margin-top: 24px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.slide-subname p {
    margin: 0;
}

.swiper-pagination{
    width: 98.6%;
    left: 16px;
}

.swiper-pagination-bullet{
    height: 32px;
    width: 32px;
    /* margin: 12px !important; */
    margin: 0 12px 26px 12px !important;
}
.swiper-pagination-bullet-active{
    background: #231F20;
}

.swiper-button-prev:after,
.swiper-button-next:after{
    color: #231F20;
    content: '';
}

.swiper-button-prev{
    margin-right: 86px;
    top: auto;
    bottom: 32px;
    left: auto;
    right: 50%;
    z-index: 300;
}
.swiper-button-next{
    margin-right: -107px;
    top: auto;
    bottom: 32px;
    left: auto;
    right: 50%;
    z-index: 300;
}

.swiper-button-prev:after{

    background-image:
    linear-gradient(to bottom right, transparent 50%, #231F20 0),
    linear-gradient(to top right, #231F20 50%, transparent 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
  rotate: 270deg;
  width: 42px;
  height: 24px;
}
.swiper-button-next:after{

    background-image:
    linear-gradient(to bottom right, transparent 50%, #231F20 0),
    linear-gradient(to top right, #231F20 50%, transparent 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
  width: 42px;
  height: 24px;
  rotate: 90deg;

}

@media only screen and (max-width: 767px) {
    .carousel-component{
        width: auto;
        margin: 48px 3.5%;
    }

    .slide-container{
        flex-direction: column;
        width: auto;
        padding: 24px 3.5% !important;
    }

    .slide-left,
    .slide-right{
        width: 100% !important; 
    }

    .slide-right{
        order: 0;
        margin: 0 auto;
    }
    .slide-left{
        order: 1;
        margin: 0 auto;
    }

    .slide-img{
        margin: 24px 0 0 0 !important;
    }

    .slide-img img{
        width: 128px !important;
        height: 128px !important;
    }

    .slide-header-container{
        display: flex;
        flex-direction: column;
        margin: 24px 0;
    }

    .slide-name{
        width: 100% !important;
    }

    .slide-name h4{
        text-align: center !important;
    }

    .slide-subname{
        width: 100% !important;
        margin-top: 0 !important;
        text-align: center !important;
    }
    .slide-subname p{
        text-align: center !important;
    }

    .slide-body{
        margin: 0 !important;
    }

    .slide-body p{
        text-align: center !important;
    }

    .slide-title{
        display: none;
    }

    .swiper-pagination-bullet{
        height: 28px;
        width: 28px;
    }

    .swiper-button-next,
    .swiper-button-prev{
        bottom: 30px !important;
    }
}
@media only screen and (min-width: 767px) {
    .swiper-pagination{
        /* left: 12px !important; */
    }
}