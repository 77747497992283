.big-text-component{
  margin: 120px 11.5%;
  z-index: 5;
  position: relative;
  display: inline;
  z-index: 12;
}
#big-text-one h1{
  margin-top: 120px;
}

.big-text-h1 {
  padding: 0 11.5%;
  max-width: 1280px;
  margin: 120px auto;
}

.big-text-a {
  font-family: inter-extra-bold;
  color: #231F20;
  font-size: 92px;
  font-weight: 1000;
  line-height: 120px;
  letter-spacing: -5px;
  cursor: default;
  position: relative;
  transition: color .7s ease-in-out;
  transition-delay: .3s;
  z-index: 4;
  text-decoration: none;
}

.big-text-a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;

  opacity: 0;
  background-color: #9BA873;
  filter: blur(24px);
  transition: opacity .7s ease-in-out;
  transition-delay: .3s;
  z-index: -1;
  padding: 12px;
  left: -12px;
  top: -6px;
  border-radius: 35%;
}


.big-text-a-fx{
  color: rgba(255, 255, 255, 0.95);
}

.big-text-a-fx:after{
  opacity: 1;
}



#big-text-two .big-text-h1{
  /* padding: 0 8%;
  margin: 200px 3.5%; */
}
@media only screen and (max-width: 1280px) {
  .big-text-h1{
    z-index: 998;
    position: relative;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .big-text-h1 {
    font-size: 48px;
    font-weight: 1000;
    line-height: 64px;
    letter-spacing: -2px;
    padding: 0 3.5%;
    margin: 48px auto !important;
  }

  .big-text-a {
    font-size: 48px;
    font-weight: 1000;
    line-height: 64px;
    letter-spacing: -2px;
  }

  .big-text-a::after {
    left: 1px;
    top: 2px;
    filter: none;
    opacity: 1;
    padding: 0;
  }
  .big-text-component{
    margin: 48px 11.5% !important;
    z-index: 5;
    position: relative;
    display: inline;
  }
}