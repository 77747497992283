@media only screen and (min-width: 1280px) {
    .reviews-component .slide-container{
        height: 420px;
    }
}

.reviews-component{
    margin: 120px auto;
    width: 1280px;
    position: relative;
}
@media only screen and (max-width: 1280px) and (min-width: 767px){
    .reviews-component{
        margin-top: 120px ;
        margin-bottom: 120px ;
        width: 100%;
        position: relative;
        max-width: 93%;
    }

    .reviews-component .slide-container{
        height:  520px;
    }
}

.reviews-component h4,
.reviews-component p{
    text-align: center;
}

.swiper{
    border-radius: 20px;
}

.swiper-wrapper{

    height: 100%;
}

.swiper-slide{

    width: 100%;
}

.reviews-component .slide-container{
    width: 93%;
    max-width: 1280px;
    /* height: 500px; */
    display: flex;
    margin: auto;
    /* background-color: rgba(217, 217, 217, 0.65); */
    border-radius: 20px;
    padding: 3.5%;
    display: flex;
    flex-direction: column;
}

.slide-left,
.slide-right{
    display: flex;
    flex-direction: column;
    width: 50%;
}


.slide-title{
    margin-right: 0;
}

.slide-body{
    margin-top: 24px;
    margin-right: 42px;
}

.slide-name{
    
}

.slide-img{
margin: 32px 0;
}

.slide-img img{
    height: 300px;
    width: 300px;
    border-radius: 50%;
}
.slide-right h4, 
.slide-right p{
    text-align: center;
}

.slide-subname{
    margin-top: 24px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.slide-subname p {
    margin: 0;
}

.swiper-pagination{
    width: 98.6%;
    left: 16px;
}

.swiper-pagination-bullet{
    height: 32px;
    width: 32px;
    /* margin: 12px !important; */
    margin: 0 12px 26px 12px !important;
}
.swiper-pagination-bullet-active{
    background: #231F20;
}

.swiper-button-prev:after,
.swiper-button-next:after{
    color: #231F20;
    content: '';
}

.swiper-button-prev{
    margin-right: 86px;
    top: auto;
    bottom: 32px;
    left: auto;
    right: 50%;
    z-index: 300;
}
.swiper-button-next{
    margin-right: -107px;
    top: auto;
    bottom: 32px;
    left: auto;
    right: 50%;
    z-index: 300;
}

.swiper-button-prev:after{

    background-image:
    linear-gradient(to bottom right, transparent 50%, #231F20 0),
    linear-gradient(to top right, #231F20 50%, transparent 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
  rotate: 270deg;
  width: 42px;
  height: 24px;
}
.swiper-button-next:after{

    background-image:
    linear-gradient(to bottom right, transparent 50%, #231F20 0),
    linear-gradient(to top right, #231F20 50%, transparent 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
  width: 42px;
  height: 24px;
  rotate: 90deg;
}

@media only screen and (max-width: 767px) {
    .reviews-component{
        width: auto;
        margin: 48px 3.5%;
        /* padding: 64px 0; */
    }

    .reviews-component .slide-container{
        height: 400px;
    }
}

@media only screen and (min-width: 767px) {
    .swiper-pagination{
        left: 2px !important;
    }

    .swiper-button-prev{
        margin-right: 92px !important;
    }
    .swiper-button-next{
        margin-right: -120px !important;
    }

}