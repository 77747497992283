@font-face {
    font-family: inter-regular;
    src: url('../Media/fonts/Inter-Regular.ttf');
  }
@font-face {
    font-family: inter-bold;
    src: url('../Media/fonts/Inter-Bold.ttf');
  }
@font-face {
    font-family: inter-extra-bold;
    src: url('../Media/fonts/Inter-ExtraBold.ttf');
  }

/* COLOURS 
GREENS
#9BA873 // rgb(155, 168, 115)
#5F7367 // rgb(95, 115, 103)
#384A4E // rgb(56, 74, 78)

BLACKS
#231F20

WHITES
rgba(217, 217, 217, 0.65);
*/
h1{
    font-family: inter-extra-bold;
    color: #231F20;
    font-size: 92px;
    font-weight: 1000;
    line-height: 120px;
    letter-spacing: -5px;
    text-align: left;
}

h2{
  font-family: inter-extra-bold;
  color: #231F20;
  font-size: 76px;
  font-weight: 1000;
  line-height: 64px;
  letter-spacing: -2px;
  text-align: left;
}

h3{
  font-family: inter-extra-bold;
  color: #231F20;
  font-size: 48px;
  font-weight: 1000;
  line-height: 64px;
  letter-spacing: -2px;
  text-align: left;
  margin: 0;
}

h4{
  font-size: 52px;
  font-family: inter-extra-bold;
  margin: 0;
  line-height: 50px;
  letter-spacing: -2px;
}

p{
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -1px;
}

h5, p, button, a{
    font-family: inter-regular;
    color: #231F20;


}

h1, h2, h3, h4, h5, p{
  text-align: left;
  color: #231F20;
}

.header-link{
  color: #231F20;
  text-decoration: none;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -1px;
  font-family: inter-extra-bold;
}


body{
    width: 100%;
    overflow-x: hidden;
    max-width: 1920px;
    margin: auto;
    background-color: rgba(217, 217, 217, 0.65);
}

html{
  overflow-x: hidden;
}

.App{
  position: relative;
}

@media only screen and (max-width: 767px) {
  h2{
    font-family: inter-extra-bold;
    color: #231F20;
    font-size: 42px;
    font-weight: 1000;
    line-height: 48px;
    letter-spacing: -1px;
    text-align: left;
    margin: 0;
  }

  h4{
    font-size: 32px;
    font-family: inter-extra-bold;
    margin: 0;
    line-height: 32px;
    letter-spacing: -1px;
  }

  p{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 1280px) {
  .margin-spacing{
    margin-left: 3.5% !important;
    margin-right: 3.5% !important;
  }
}